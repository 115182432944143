// Bootstrap Framework
import './scss/index.scss';
import 'popper.js';
import 'bootstrap';

import "slick-carousel";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import scrollMonitor from 'scrollmonitor';
import imagesLoaded from 'imagesloaded';
import 'jquery-match-height';
import '@fancyapps/fancybox';

$(function ()
{
    //#region toggle and mobile config navbar
    $('#navbarMain').on('show.bs.collapse', function (e) {
        $('.navbar-toggler .hamburger').addClass('is-active');
        let scrollableNav = document.querySelector('#navbarMain');
        scrollableNav.scrollTo(0, 0);
        configNavbar();
        disableBodyScroll(scrollableNav);
    });
    $('#navbarMain').on('hidden.bs.collapse', function (e) {
        $('.navbar-toggler .hamburger').removeClass('is-active');
        let scrollableNav = document.querySelector('#navbarMain');
        enableBodyScroll(scrollableNav);
    });
    function configNavbar() {
        if ($(window).outerWidth() < 992) {
            let nbHeight = $('.navbar').outerHeight();
            $('.navbar-collapse').css('top', nbHeight);
            $('.navbar-collapse').css('height', `calc(100vh-${nbHeight}px)`);
        }
    }
    configNavbar();
    //#endregion

    //#region sticky navbar
    var hbEl = document.querySelector(".headbar");
    var hbWatcher = scrollMonitor.create(hbEl);
    hbWatcher.lock();
    hbWatcher.enterViewport(function () {
        $(hbEl).removeClass('sticky');
    });
    hbWatcher.exitViewport(function () {
        $(hbEl).addClass('sticky');
    });
    //#endregion

    //#region anchors
    var aEl = document.querySelector(".anchors");
    if (aEl) {
        var aWatcher = scrollMonitor.create(aEl, -160);
        aWatcher.lock();
        aWatcher.enterViewport(function () {
            $(aEl).removeClass('sticky');
        });
        aWatcher.exitViewport(function () {
            $(aEl).css('top', `${$(hbEl).outerHeight() - 1}px`);
            $(aEl).addClass('sticky');
        });

        $(window).on('scroll', function () {
            $('.anchors').css('top', `${$('.headbar').outerHeight() - 1}px`);
        });
    }
    //#endregion

    //#region searchbar
    $('.js-toggle-searchbar').on('click', function (e) {
        e.preventDefault();
        $('.searchbar').toggleClass('show');
    })
    //#endregion

    //#region products box
    $('.box__image').matchHeight();
    $('.box__title').matchHeight();
    //#endregion

    //#region gallery
    $('.gallery__item').matchHeight();
    //#endregion

    //#region gallery prodotto
    $('.gallery-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.gallery-nav',
        autoplay: true,
        autoplaySpeed: 1000
    });
    $('.gallery-nav').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        asNavFor: '.gallery-for',
        focusOnSelect: true
    });
    //#endregion

    //#region slider
    let $slider = $('.slider__slider');
    $slider.on('init', function (event, slick) {
        if (slick.$slides.length > 1) {
            $('.slider__navigation__pages > span:first-child').text(1);
            $('.slider__navigation__pages > span:last-child').text(slick.$slides.length);
            $('.slider__navigation').addClass('loaded');
        }
    });
    $slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $('.slider__navigation__pages > span:first-child').text(nextSlide + 1);
    });
    $slider.slick({
        prevArrow: '.slider__navigation__arrow--prev',
        nextArrow: '.slider__navigation__arrow--next',
    });
    //#endregion
})